<template>
  <div class="maker-store-invite">
    <van-nav-bar title="店铺主题认证" fixed placeholder />

    <div class="background"></div>

    <div class="main">
      <div class="header">
        <div class="title">请选择认证类型</div>
        <div class="desc">请按真实经营情况慎重选择</div>
      </div>

      <div class="list">
        <div
          class="list-item"
          v-for="(item, index) in list"
          :key="index"
          @click="jumpTo"
        >
          <van-image
            width="48"
            height="48"
            :src="
              item.id === '1'
                ? picPerson
                : item.id === '2'
                ? picCompany
                : picMerchant
            "
          />
          <div class="item-right">
            <div class="right-title">{{ item.name }}</div>
            <div class="right-desc">{{ item.explains }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTypes } from "@/api/makerStore";
import { openApp } from "@/common";
export default {
  name: "makerStoreInvite",
  data() {
    return {
      list: [],
      picPerson: require("../../assets/maker/store-person.png"),
      picCompany: require("../../assets/maker/store-company.png"),
      picMerchant: require("../../assets/maker/store-merchant.png"),
    };
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session);
    this.getTypes();
    this.openApp('jump?type=1', true);
  },
  methods: {
    getTypes() {
      getTypes().then((res) => {
        console.log(res);
        this.list = res.data.data;
      });
    },
    jumpTo() {
      openApp('jump?type=1', true);
    },
  },
};
</script>

<style lang="less" scoped>
.maker-store-invite {
  position: relative;
  background-color: #f6f6f6;
  /deep/.van-nav-bar__content {
    background-color: #ff7200;
  }
  /deep/.van-nav-bar__title {
    color: #fff;
  }
  .background {
    height: 140px;
    background-color: #343748;
  }
  .main {
    position: absolute;
    top: 46px;
    width: 100%;
    .header {
      text-align: center;
      padding-top: 28px;
      padding-bottom: 16px;
      .title {
        font-size: 24px;
        color: #fff;
        margin-bottom: 4px;
      }
      .desc {
        font-size: 16px;
        color: #999;
      }
    }
  }
  .list {
    .list-item {
      background-color: #fff;
      margin: 0 12px 10px;
      padding: 25px;
      border-radius: 5px;
      display: flex;
      .van-image {
        flex-shrink: 0;
        margin-right: 13px;
      }
      .item-right {
        .right-title {
          color: #333333;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 2px;
        }
        .right-desc {
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }
}
</style>